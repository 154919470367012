.main-hero{
    width: 100%;
    height: 100vh;
    background-image: url("./img/bg1.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    background-attachment: fixed;
    z-index: -1;
}

a{
    color: white;
    text-decoration: none;
}

a:hover{
    color: white;
    text-decoration: none;
}

.clear{
    clear: both;
}

.upper{
    text-transform: uppercase;
}

.poppins{
    font-family: 'poppins', sans-serif;
}

.white{
    color: white;
}

.blue{
    color: #36C6F5;
}

.text-center{
    text-align: center;
}

.text-shadow{
    text-shadow: 2px 5px 5px rgba(0,0,0,0.75);
}

.title-margin{
    margin: 25px auto;
}

.extra-margin{
    margin: 50px auto 25px;
}

.side-padding{
    padding: 0px 50px;
}

.text-small{
    font-size: 18px;
}

.text-xtrasmall{
    font-size: 12px;
}

.underline{
    text-decoration: underline;
}

.required{
    font-size: 18px;
    color:red;
}

.hidden{
    display: none;
}

.display{
    display: block;
    color: red;
}

.section-divider{
    height: 100px;
}

.svg-separator{
    background: 0 0;
    z-index: 9;
    margin: 100px auto 0px;
    -webkit-transform: translateY(-100%) translateY(2px);
    transform: translateY(-100%) translateY(2px);
    width: 100%;
    height: 100px;
}

.sep1{
transform: translateY(-100%) translateY(2px) scale(1,1);
transform-origin: top;
}

.line-separator{
    position: relative;
}

.line-separator:before {
    content: "s"; 
    width: 5.5rem;
    height: 5.5rem;
    background: #ffffff;
    border-radius: 100%;
    position: absolute;
    top: -1.50rem;
    left: calc(50% - 3.25rem);
    color: #ffffff;
    line-height: 3rem;
    text-indent: 1.75rem;
    font-size: 3rem;
    }

.header{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.75);
    background-color: #ffffff;
}

.logo-cont{
    width: 20%;
}

#logo{
    width: 75px;
}

.main-nav{
    width: 60%;
}

.nav-bar{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-evenly;
    align-items: center;
}

.nav-item{
    list-style: none !important;
}

.navbar-dark .navbar-nav .nav-link{
    color: black !important;
}

.navbar-dark .navbar-toggler{
    background-color: #36c6f5 !important;
}

.navbar-dark .navbar-toggler-icon{
    background-color: #36c6f5 !important;
}

.nav-item{
    font-family: 'poppins', sans-serif !important;
    cursor: pointer !important;
}

.grid-container{
    display: grid;
    margin: 0 auto;
    width: 100%;
    height: 90vh;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
    grid-template-rows: 10% 10% 10% 10% 6% 6% 6% 10% 10% 10%;
}

#grid-item1{
    grid-column-start: 4;
    grid-column-end: 11;
    grid-row-start: 5;
    grid-row-end: 6;
}

#grid-item2{
    grid-column-start: 4;
    grid-column-end: 11;
    grid-row-start: 6;
    grid-row-end: 7;
}

#grid-item3{
    grid-column-start: 4;
    grid-column-end: 11;
    grid-row-start: 7;
    grid-row-end: 8;
}

.myself-cont{
    margin: 0px auto 50px;
}

.headshot-cont{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

#headshot{
    margin: 25px auto 0px;
    border-radius: 50%;
    border: 5px solid black;
}

#caption{
    margin:10px auto 0px;
    width: 50%;
    text-align: center;
    font-style: italic;
}

.about-me-cont{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.about-me{
    margin: 10px auto;
}

#mekong-bg{
    width: 100%;
    height: 100vh;
    background-image: url('./img/mekongboat.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.parent-proj-cont{
    padding: 25px auto;
}

.proj-cont{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    justify-content: space-between;
    align-items: center;
    border: 3px solid black;
    border-radius: 6px;
    box-shadow: 2px 5px 5px rgba(0,0,0, 0.5);
    padding: 25px;
    margin: 25px auto;
    background-color: white;
}

.proj-img-cont, .proj-title-cont{
    width: 100%;
    height: 100%;
    margin: 15px auto;
}

.modal-btn-cont{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.modal-btn{
    width: 75%;
    background: #36c6f5;
    padding: 10px 25px;
    border-radius: 25px;
    color: white;
    text-shadow: 1px 2px 2px rgba(0,0,0,0.5);
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
    box-shadow: 2px 5px 5px rgba(0,0,0, 0.5);
}

.modal-btn:focus{
    outline: none;
}

.proj-img{
    width: 100%;
    border: 2px solid rgba(0,0,0);
    box-shadow: 1px 3px 3px rgba(0,0,0, 0.5);
}

#website-projects{
    background: #36C6F5;
    width: 100%;
    height: 100%;
}

.main-timeline {
    position: relative
}

.main-timeline:before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background: rgba(54,198,245, 0.5);
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.main-timeline .timeline {
    margin-bottom: 40px;
    position: relative
}

.main-timeline .timeline:after {
    content: "";
    display: block;
    clear: both
}

.main-timeline .icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.main-timeline .icon:before,
.main-timeline .icon:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.33s ease-out 0s
}

.main-timeline .icon:before {
    background: #fff;
    border: 2px solid #36C6F5;
    left: -3px
}

.main-timeline .icon:after {
    border: 2px solid rgba(54,198,245, 0.5);
    left: 3px
}

.main-timeline .timeline:hover .icon:before {
    left: 3px
}

.main-timeline .timeline:hover .icon:after {
    left: -3px
}

.main-timeline .date-content {
    width: 50%;
    float: left;
    margin-top: 22px;
    position: relative
}

.main-timeline .date-content:before {
    content: "";
    width: 36.5%;
    height: 2px;
    background: rgba(54,198,245, 0.5);
    margin: auto 0;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0
}

.main-timeline .date-outer {
    width: 125px;
    height: 125px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    z-index: 1
}

.main-timeline .date-outer:before,
.main-timeline .date-outer:after {
    content: "";
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.33s ease-out 0s
}

.main-timeline .date-outer:before {
    background: #fff;
    border: 2px solid #36C6F5;
    left: -6px
}

.main-timeline .date-outer:after {
    border: 2px solid rgba(54,198,245, 0.4);
    left: 6px
}

.main-timeline .timeline:hover .date-outer:before {
    left: 6px
}

.main-timeline .timeline:hover .date-outer:after {
    left: -6px
}

.main-timeline .date {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 27%;
    left: 0
}

.main-timeline .month {
    font-size: 18px;
    font-weight: 700
}

.main-timeline .year {
    display: block;
    font-size: 30px;
    font-weight: 700;
    color: #36C6F5;
    line-height: 36px
}

.main-timeline .timeline-content {
    width: 50%;
    padding: 20px 0 20px 50px;
    float: right
}

.main-timeline .title {
    font-size: 19px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 15px 0
}

.main-timeline .description {
    margin-bottom: 0
}

.main-timeline .timeline:nth-child(2n) .date-content {
    float: right
}

.main-timeline .timeline:nth-child(2n) .date-content:before {
    left: 10px
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 20px 50px 20px 0;
    text-align: right
}

@media only screen and (max-width: 991px) {
    .main-timeline .date-content {
        margin-top: 35px
    }
    .main-timeline .date-content:before {
        width: 22.5%
    }
    .main-timeline .timeline-content {
        padding: 10px 0 10px 30px
    }
    .main-timeline .title {
        font-size: 17px
    }
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        padding: 10px 30px 10px 0
    }
}

@media only screen and (max-width: 767px) {
    .main-timeline:before {
        margin: 0;
        left: 7px
    }
    .main-timeline .timeline {
        margin-bottom: 20px
    }
    .main-timeline .timeline:last-child {
        margin-bottom: 0
    }
    .main-timeline .icon {
        margin: auto 0
    }
    .main-timeline .date-content {
        width: 95%;
        float: right;
        margin-top: 0
    }
    .main-timeline .date-content:before {
        display: none
    }
    .main-timeline .date-outer {
        width: 110px;
        height: 110px
    }
    .main-timeline .date-outer:before,
    .main-timeline .date-outer:after {
        width: 110px;
        height: 110px
    }
    .main-timeline .date {
        top: 30%
    }
    .main-timeline .year {
        font-size: 24px
    }
    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content {
        width: 95%;
        text-align: center;
        padding: 10px 0
    }
    .main-timeline .title {
        margin-bottom: 10px
    }
}

.skill-containerm, .skill-img-cont, .skill-img, .skill-desc-cont{
    width: 75%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
}

#cv{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: underline;
    font-size: 18px;
    padding: 0px 15px 15px;
}

#cv:hover{
    text-decoration: none;
}

#bottom-bg{
    width: 100%;
    height: 100vh;
    background-image: url('./img/fishgreen.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.form-container{
    width: 50%;
    height: 100%;
    margin: 15px auto 25px;
}

#contactForm, .form-row{
    width: 100%;
    height: 100%;
}

.flex-row{
    display: flex;
    width: 50%;
    height: auto;
    justify-content: flex-start;
    align-items: center;
}

.message-row, .message-box, .form-input{
    width: 100%;
    height: auto;
    margin: 5px auto;
    border: none;
}

.message-box, .form-input{
    border-bottom: 2px dotted black;
}

.message-box{
    padding: 10px;
    margin: 0 10px;
}

.form-btn{
    padding: 5px 10px;
    margin: 0 10px;
    width: 50%;
    background-color: #36C6F5;
    color: white;
    border: none;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.25);
    border-radius: 25px;
}

.form-input, .form-label{
    padding: 10px;
    margin: 5px 10px;
}

.form-input:focus, .message-box:focus, .form-btn:focus{
    outline: none;
}

.form-input::placeholder, .message-box::placeholder{
    font-family: 'Raleway', sans-serif;;
}

@media only screen and (max-width: 1024px)
{
    .main-hero{
        height: 65vh;
    }

    .xs-dev-font{
        font-size: 18px;
    }

    #about-me-title{
        margin: 50px auto 25px;
    }

    .grid-container{
        height: 60vh;
        grid-template-rows: 10% 10% 10% 10% 4% 4% 4% 10% 10% 10%;
    }

    #grid-item1, #grid-item2, #grid-item3{
        grid-column-start: 4;
    }

    #mekong-bg, #bottom-bg{
        height: 50vh;
    }
}

@media only screen and (max-width: 768px)
{
    #grid-item1, #grid-item2, #grid-item3{
        grid-column-start: 4;
    }

    #about-me{
        text-align: center;
    }

    .form-container{
        width: 75%;
    }

    #tech-skills-title, #soft-skills-title{
        display: none;
    }
}

@media only screen and (max-width: 415px)
{
    .xs-dev-font{
        font-size: 18px;
    }

    .side-padding{
        padding: 0px 25px;
    }

    .form-container{
        width: 100%;
    }
}




