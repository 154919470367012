* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Raleway', sans-serif;
}

#loading{
  position: absolute;
  top: 65%;
  width: 100%;
  text-align: center;
  z-index: 100;
  display:block;
  font-family: 'poppins', sans-serif;
  font-size: 24px;
}
